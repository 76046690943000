import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPage = ({ data }) => {
  const articles = data.Directus.Article || [];

  if (articles.length === 0) {
    return (<p>Ops... estamos atualizando essa página.</p>);
  }

  return (
    <Layout>
      <Seo title="Blog" description="Artigos sobre liderança e gestão" />
      <h1>Artigos sobre liderança e gestão</h1>
      {articles.reverse().map(i => {
        return (
          <div>
            {/* {i.CoverImage != null && <img src={`https://cms.wesolveit.com.br/assets/${i.CoverImage.filename_disk}?fit=cover&width=1245&quality=80`} alt={i.CoverImage.description} />} */}
            <h2><Link to={`/blog/${i.Slug}`} title={`${i.Title}`}>{i.Title}</Link></h2>
            <hr />
            <p>{i.MetaDescription}</p>
          </div>
        );
      })}
      <div className="pb-16"></div>
      <div className="pb-16"></div>
    </Layout>
  );
}

export const query = graphql`
query Articles {
  Directus {
    Article(filter: {status: {_eq: "published"}}, sort: "date_created") {
      Id
      Title
      Slug
      MetaDescription
      CoverImage {
        filename_disk
        description
      }
      date_created
    }
  }
}
    `

export default BlogPage